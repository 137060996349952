import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import icons from '../../assets/icons/icons';
import { getQuery } from '../../helpers/utils';
import config from '../../config/config';

const Search = ({ location, onSubmit }: any) => {
  const [search, setSearch] = useState('');

  useEffect(() => {
    const s = getQuery(location).get(config.searchParam);
    if (s) {
      setSearch(s);
    } else {
      setSearch('');
    }
  }, [location]);

  const submit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    onSubmit(search);
  };

  return (
    <Form onSubmit={submit}>
      <div className="app-search-box">
        <div className="app-input-box">
          <Form.Control
            type="text"
            name="search"
            placeholder="Search..."
            value={search}
            onChange={event => {
              setSearch(event.target.value);
            }}
          />
          {search && search.length > 0 && (
            <span
              className="app-input-clear"
              onClick={() => {
                setSearch('');
              }}
            >
              {icons.close('#000', '')}
            </span>
          )}
        </div>
        <Button type="submit" variant="dark">
          Search
        </Button>
      </div>
    </Form>
  );
};

Search.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  location: PropTypes.any.isRequired
};

export default Search;
