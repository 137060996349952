import React from 'react';
import PropTypes from 'prop-types';
import routes from '../../../config/routes';
import Link from 'next/link';

const RecentArticle = ({ data, title }: any) => {
  return (
    data &&
    data.length > 0 && (
      <div className="app-recent-post">
        {title && <h2>{title}</h2>}
        <ul>
          {data.map((item: any, i: number) => {
            return (
              <li key={i}>
                <Link href={`${routes.blogsUrl}/${item.slug}`}>{item.title}</Link>
              </li>
            );
          })}
        </ul>
      </div>
    )
  );
};

RecentArticle.propTypes = {
  data: PropTypes.any.isRequired,
  title: PropTypes.string
};

export default RecentArticle;
