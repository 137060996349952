import React from 'react';
import PropTypes from 'prop-types';
import { getQuery, pagination } from '../../helpers/utils';
import config from '../../config/config';
import { useRouter } from 'next/router';

const Pagination = ({ currentPage, totalRecords, recordsPerPage, Change, history, path, searchParam }: any) => {
  history = useRouter()
  const noOfPages = Math.ceil(totalRecords / recordsPerPage);
  const Navigate = (PageNumber: number) => {
    let url;

    if (searchParam) {
      const search = getQuery(searchParam.search).get(config.searchParam);
      if (search || search === '') {
        url = `${path}/${PageNumber}/?${config.searchParam}=${search}`;
      } else {
        url = `${path}/${PageNumber}/${searchParam}`;
      }
    } else {
      url = `${path}/${PageNumber}`;
    }
    history.push(url);
    Change(PageNumber);
  };

  return (
    <React.Fragment>
      {noOfPages > 1 && (
        <ul className="app-pagination">
          <li
            onClick={() => {
              if (currentPage > 1) {
                Navigate(currentPage - 1);
              }
            }}
            className={currentPage === 1 ? 'active' : ''}
          >
            « Previous
          </li>
          {pagination(currentPage, noOfPages).map((page: any, i: number) => {
            return (
              <li
                onClick={() => {
                  if (page !== '...' && currentPage !== page) {
                    Navigate(page);
                  }
                }}
                className={(currentPage === page ? 'active ' : ' ') + (page !== '...' ? ' ' : ' rest')}
                key={i}
              >
                {page}
              </li>
            );
          })}
          <li
            onClick={() => {
              if (currentPage < noOfPages) {
                Navigate(currentPage + 1);
              }
            }}
            className={currentPage < noOfPages ? '' : 'disable'}
          >
            Next »
          </li>
        </ul>
      )}
    </React.Fragment>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalRecords: PropTypes.number.isRequired,
  recordsPerPage: PropTypes.number.isRequired,
  Change: PropTypes.func.isRequired,
  history: PropTypes.object,
  path: PropTypes.string.isRequired,
  searchParam: PropTypes.any
};

export default Pagination;
