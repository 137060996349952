import React from 'react';
import PropTypes from 'prop-types';
import routes from '../../../config/routes';
import Link from 'next/link';

const SearchCard = ({ cardData }: any) => {
  return (
    cardData && (
      <div className="app-search-card">
        <h2>
          <Link href={`${cardData.type === 'product' ? routes.productUrl : routes.blogsUrl}/${cardData.slug}`}>
            <a>{cardData.title}</a>
          </Link>
        </h2>
        <div className="app-search-card-summary">
          <p>
            {cardData.description}{' '}
            <Link href={`${cardData.type === 'product' ? routes.productUrl : routes.blogsUrl}/${cardData.slug}`}>
              <a> Read more</a>
            </Link>
          </p>
        </div>
      </div>
    )
  );
};

SearchCard.propTypes = {
  cardData: PropTypes.any.isRequired
};

export default SearchCard;
