import React, { useEffect, useState } from 'react';
import RecentArticle from '../Blog/RecentArticle/RecentArticle';
import { Col, Row } from 'react-bootstrap';
import Search from './Search';
import SearchCard from './SearchCard/SearchCard';
import Pagination from '../Pagination/Pagination';
import config from '../../config/config';
import { getQuery } from '../../helpers/utils';
import { useRouter } from 'next/router';

const SearchPage = ({ history, data, match, location }: any) => {
  history = useRouter()
  const [notFoundTitle, setNotFoundTitle] = useState({ title: '', description: '' });
  useEffect(() => {
    const search = getQuery(location).get('s');

    if (search) {
      setNotFoundTitle({ title: config.searchNotFoundTitle, description: config.searchNotFoundDescription });
    } else {
      const title =
        data.Body &&
        data.Body.length > 0 &&
        data.Body.find((item: any) => item.__component === 'notfound.page-not-found');
      if (title) {
        setNotFoundTitle({ title: title.title, description: title.subTitle });
      } else {
        setNotFoundTitle({ title: config.pageNotFoundTitle, description: config.pageNotFoundDescription });
      }
    }
  }, [location, data.Body]);
  const getTitle = () => {
    const title =
      data.Body &&
      data.Body.length > 0 &&
      data.Body.find((item: any) => item.__component === 'blog.recent-post-widget');
    if (title) {
      return title.title;
    } else {
      return 'Recent Posts from Nexersys';
    }
  };
  return (
    data && (
      <div className="app-search-page">
        <div className="container">
          <Row xl={2} sm={1} lg={2}>
            <Col lg={8} xl={9}>
              {((data.data && data.data.results && data.data.results.length === 0) ||
                (data.data && !data.data.results)) && (
                  <div className="app-search-no-result">
                    <h1 className="app-search-header">{notFoundTitle.title}</h1>
                    <div className="app-search-body">
                      <p className="app-header-subtitle">{notFoundTitle.description}</p>
                      <Search
                        onSubmit={event => {
                          history.push(`/search/?${config.searchParam}=${event}`);
                        }}
                        location={location}
                      />
                    </div>
                  </div>
                )}
              {data.data &&
                data.data.results &&
                data.data.results.length > 0 &&
                data.data.results.map((item: any, i: number) => {
                  return <SearchCard key={i} cardData={item} />;
                })}
              {data.data.page && (
                <Pagination
                  currentPage={match.query.pageno ? +match.query.pageno : 1}
                  totalRecords={data.data.page.totalElements ? data.data.page.totalElements : 0}
                  recordsPerPage={data.data.page._limit}
                  history={history}
                  Change={event => {
                    console.log(event);
                  }}
                  path="/search/page"
                  searchParam={location}
                />
              )}
            </Col>
            <Col lg={4} xl={3}>
              {data.data.recentPosts && data.data.recentPosts.length > 0 && (
                <RecentArticle data={data.data.recentPosts} title={getTitle()} />
              )}
            </Col>
          </Row>
        </div>
      </div>
    )
  );
};

export default SearchPage;
